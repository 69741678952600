import React, { FC, ReactNode } from 'react'
import { Link as GatsbyRouterLink } from 'gatsby'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { getNeedReload } from '@ads/front-core/store/selectors/getNeedReload'

interface BaseLinkProps {
  disabled?: boolean
  underline?: boolean
}

export interface LinkProps extends BaseLinkProps {
  to: string
  className?: string
  onClick?: (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  children?: ReactNode
}

export interface ExternalTextLinkProps
  extends Pick<HTMLAnchorElement, 'href' | 'target'> {
  underline?: boolean
  disabled?: boolean
  children?: ReactNode
  className?: string
  onClick?: (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

export const TextLink: FC<LinkProps> = ({
  to,
  disabled,
  children,
  className,
  onClick,
}) => {
  const needReload = useSelector(getNeedReload)

  if (!disabled && needReload) {
    return (
      <ExternalTextLink
        href={to}
        disabled={disabled}
        target="_self"
        className={className}
        onClick={onClick}
      >
        {children}
      </ExternalTextLink>
    )
  }

  if (disabled) {
    return (
      <TextLinkStyledDisabled className={className}>
        {children}
      </TextLinkStyledDisabled>
    )
  }
  return (
    <TextLinkStyled to={to} onClick={onClick} className={className}>
      {children}
    </TextLinkStyled>
  )
}

export const ExternalTextLink: FC<ExternalTextLinkProps> = props => (
  <ExternalTextLinkStyled {...props} />
)

const Link: FC<LinkProps> = ({ disabled, children, ...rest }) => {
  const needReload = useSelector(getNeedReload)

  if (!disabled && needReload) {
    return (
      <ExternalTextLink
        href={rest.to}
        disabled={disabled}
        target="_self"
        {...rest}
      >
        {children}
      </ExternalTextLink>
    )
  }

  if (disabled) {
    return <DisabledLink>{children}</DisabledLink>
  }
  return <GatsbyRouterLink {...rest}>{children}</GatsbyRouterLink>
}

const DisabledLink = styled.span``

const textLinkStyle = css<ExternalTextLinkProps | BaseLinkProps>`
  ${p => css`
    color: ${p.disabled ? p.theme.colors.grey50 : p.theme.colors.primary50};
    text-decoration: ${p.underline ? 'underline' : 'none'};
    cursor: ${p.disabled ? 'not-allowed' : 'pointer'};

    &:hover {
      text-decoration: underline;
    }
  `};
`

const ExternalTextLinkStyled = styled.a<ExternalTextLinkProps>`
  ${textLinkStyle}
`

const TextLinkStyled = styled(GatsbyRouterLink)<LinkProps>`
  ${textLinkStyle}
`

const TextLinkStyledDisabled = styled.span<BaseLinkProps>`
  ${textLinkStyle}
`

export default Link

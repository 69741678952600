import { createAction } from '@reduxjs/toolkit'
import { AppState } from '../../appState'

export const setWizardCategory = createAction<AppState['wizard']['category']>(
  'wizard/setWizardCategory',
)

export const setWizardCategoryReducer = (
  state: AppState['wizard'],
  action: ReturnType<typeof setWizardCategory>,
): void => {
  state.category = action.payload
  state.productType = undefined
}

import { createAction } from '@reduxjs/toolkit'
import { RootState } from '../../rootState'

export const markFirstVisit = createAction('user/markFirstVisit')

export const markFirstVisitReducer = (state: RootState['user']): void => {
  state.clients = state.clients?.map(c => ({
    ...c,
    flags: [...(c.flags || []), 'WELCOME_BOARD_SHOWED'],
  }))
}

import { GenericCampaignCreationPropertiesType } from '@ads/front-core/api'
import { Extensions } from '../interfaces'

export interface IGenericCreationFileParameter {
  extensions: Extensions[]
  maxFileSize: string
  maxFileSizeInBytes: number
  type: 'IMAGE' | 'ZIP' | 'TEXT'
}

export interface IGenericCreationParameters {
  dimension: string
  type: 'IMAGE' | 'ZIP' | 'TEXT' | 'ANY'
  fileParameters: IGenericCreationFileParameter[]
}

const DEFAULT_IMAGE_EXTENSIONS = [
  Extensions.PNG,
  Extensions.JPG,
  Extensions.JPEG,
]

const createImageFileParameters = (
  maxFileSizeInKb: number,
  extensions: Extensions[] = DEFAULT_IMAGE_EXTENSIONS,
): IGenericCreationFileParameter => {
  return {
    extensions,
    maxFileSize: `${maxFileSizeInKb}kB`,
    maxFileSizeInBytes: 1024 * maxFileSizeInKb,
    type: 'IMAGE',
  }
}

const zipFileParameters: IGenericCreationFileParameter = {
  extensions: [Extensions.ZIP],
  maxFileSize: '150kB',
  maxFileSizeInBytes: 1024 * 150,
  type: 'ZIP',
}

export const genericCreationParameters: Record<
  GenericCampaignCreationPropertiesType,
  IGenericCreationParameters
> = {
  VIDEO: {
    dimension: 'mp4, 1920x1080 / 16:9, 1024x576 / 16:9, 1280x720 / 16:9',
    type: 'IMAGE',
    fileParameters: [
      {
        extensions: [Extensions.MP4],
        maxFileSize: '5MB',
        maxFileSizeInBytes: 1024 * 1024 * 5,
        type: 'IMAGE',
      },
    ],
  },
  BAR: {
    dimension: '300x50',
    type: 'IMAGE',
    fileParameters: [createImageFileParameters(30)],
  },
  BAR_LP_WP: {
    dimension: '300x60',
    type: 'IMAGE',
    fileParameters: [createImageFileParameters(30)],
  },
  STICKY: {
    dimension: '320x50',
    type: 'IMAGE',
    fileParameters: [createImageFileParameters(30)],
  },
  CONTENT_BOX_MEDIUM: {
    dimension: '940x300',
    type: 'IMAGE',
    fileParameters: [createImageFileParameters(60)],
  },
  CONTENT_BOX_LARGE: {
    dimension: '1260x300',
    type: 'IMAGE',
    fileParameters: [createImageFileParameters(150)],
  },
  BANNER_300x250: {
    dimension: '300x250',
    type: 'IMAGE',
    fileParameters: [
      createImageFileParameters(60, [
        ...DEFAULT_IMAGE_EXTENSIONS,
        Extensions.GIF,
      ]),
      zipFileParameters,
    ],
  },
  BANNER_300x600: {
    dimension: '300x600',
    type: 'IMAGE',
    fileParameters: [
      createImageFileParameters(60, [
        ...DEFAULT_IMAGE_EXTENSIONS,
        Extensions.GIF,
      ]),
      zipFileParameters,
    ],
  },
  BANNER_750x200: {
    dimension: '750x200',
    type: 'IMAGE',
    fileParameters: [
      createImageFileParameters(60, [
        ...DEFAULT_IMAGE_EXTENSIONS,
        Extensions.GIF,
      ]),
      zipFileParameters,
    ],
  },
  BANNER_350x216: {
    dimension: '350x216',
    type: 'IMAGE',
    fileParameters: [createImageFileParameters(50)],
  },
  CUBE_3D: {
    dimension: undefined,
    type: 'ZIP',
    fileParameters: [zipFileParameters],
  },
  MOTION_AD: {
    dimension: undefined,
    type: 'ZIP',
    fileParameters: [zipFileParameters],
  },
  SCRATCH: {
    dimension: undefined,
    type: 'ZIP',
    fileParameters: [zipFileParameters],
  },
  SLASH: {
    dimension: undefined,
    type: 'ZIP',
    fileParameters: [zipFileParameters],
  },
  RECTANGLE_300x250: {
    dimension: '300x250',
    type: 'IMAGE',
    fileParameters: [createImageFileParameters(40), zipFileParameters],
  },
  MEGA_DOUBLE_BILLBOARD_970x300: {
    dimension: '970x300',
    type: 'IMAGE',
    fileParameters: [createImageFileParameters(70), zipFileParameters],
  },
  DOUBLE_BILLBOARD_750x200: {
    dimension: '750x200',
    type: 'IMAGE',
    fileParameters: [createImageFileParameters(50), zipFileParameters],
  },
  HALFPAGE_300x600: {
    dimension: '300x600',
    type: 'IMAGE',
    fileParameters: [createImageFileParameters(60), zipFileParameters],
  },
  TRIPLE_BILLBOARD_750x300: {
    dimension: '750x300',
    type: 'IMAGE',
    fileParameters: [createImageFileParameters(60), zipFileParameters],
  },
  BANNER_300x100: {
    dimension: '300x100',
    type: 'IMAGE',
    fileParameters: [createImageFileParameters(20), zipFileParameters],
  },
  WIDEBOARD_970x200: {
    dimension: '970x200',
    type: 'IMAGE',
    fileParameters: [createImageFileParameters(60), zipFileParameters],
  },
  SKYSCRAPER_160x600: {
    dimension: '160x600',
    type: 'IMAGE',
    fileParameters: [createImageFileParameters(40), zipFileParameters],
  },
  BILLBOARD_750x100: {
    dimension: '750x100',
    type: 'IMAGE',
    fileParameters: [createImageFileParameters(40), zipFileParameters],
  },
  SCALABLE_XL_BANNER_600x400: {
    dimension: '600x400',
    type: 'IMAGE',
    fileParameters: [createImageFileParameters(40), zipFileParameters],
  },
  ANY_FILE_RESOURCE: {
    dimension: null,
    type: 'ANY',
    fileParameters: [],
  },
  ANY_ARTICLE_RESOURCE: {
    dimension: null,
    type: 'ANY',
    fileParameters: [],
  },
  ARTICLE_CONTENT_RESOURCE: {
    dimension: null,
    type: 'TEXT',
    fileParameters: [
      {
        extensions: [
          Extensions.DOC,
          Extensions.DOCX,
          Extensions.PDF,
          Extensions.TXT,
        ],
        maxFileSize: '5MB',
        maxFileSizeInBytes: 1024 * 1024 * 5,
        type: 'TEXT',
      },
    ],
  },
  BANNER_1200x600: {
    dimension: '1200x600',
    type: 'IMAGE',
    fileParameters: [createImageFileParameters(150), zipFileParameters],
  },
  BANNER_1920x870: {
    dimension: '1920x870',
    type: 'IMAGE',
    fileParameters: [createImageFileParameters(150), zipFileParameters],
  },
  BANNER_320x370: {
    dimension: '320x370',
    type: 'IMAGE',
    fileParameters: [createImageFileParameters(60), zipFileParameters],
  },
  BANNER_360x600: {
    dimension: '360x600',
    type: 'IMAGE',
    fileParameters: [createImageFileParameters(60), zipFileParameters],
  },
  BANNER_640x280: null,
  BANNER_1260x300: null,
  BANNER_1260x600: null,
  BANNER_600x200: null,
  BANNER_600x400: null,
  BANNER_940x300: null,
  BANNER_940x600: null,
}

export const getGenericCreationParameters = (
  type: GenericCampaignCreationPropertiesType,
): IGenericCreationParameters => genericCreationParameters[type]

export const getGenericCreationDimension = (
  types: GenericCampaignCreationPropertiesType[],
): string =>
  types.map(type => genericCreationParameters[type].dimension).join(', ')

import React, { FC } from 'react'
import styled from 'styled-components'
import { Icon } from '@ads/front-ds'
import { ExternalTextLink } from '@ads/front-core/atoms'
import { EMAIL_SUPPORT } from '@ads/front-core/constants'
import { FingerPrint } from './Fingerprint'

export const ADBLOCK_INFO_CLASSNAME = 'pUcHacZ'

const AdblockInfo: FC = () => (
  <Wrapper className={ADBLOCK_INFO_CLASSNAME}>
    <div className="one" />
    <div className="two">
      <button>
        <Icon name="20-close" />
      </button>
      <h1>Wykryty AdBlock</h1>
      <p>
        Aby móc korzystać z panelu WP Ads,
        <br />
        <b>wyłącz oprogramowanie blokujące reklamy</b>,<br />
        bądź dodaj naszą witrynę do wyjątków.
        <br />
        <br />W razie problemów skontaktuj się z naszym działem wsparcia:{' '}
        <ExternalTextLink href={`mailto:${EMAIL_SUPPORT}`} target="_blank">
          {EMAIL_SUPPORT}
        </ExternalTextLink>
      </p>
      <div>
        <FingerPrint />
      </div>
    </div>
  </Wrapper>
)

const Wrapper = styled.div`
  display: none;
`

export default AdblockInfo

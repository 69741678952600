import {
  GenericCampaignTargeting,
  GenericCampaignTargetingType,
} from '@ads/front-core/api'

export const TARGETING_KEY: Partial<
  Record<GenericCampaignTargetingType, keyof GenericCampaignTargeting>
> = {
  SEGMENT: 'segment',
  TOPIC: 'topic',
}

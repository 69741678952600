export const connectAdjacentAges = (
  selectedTargetingAge: string[],
): string[] => {
  if (!selectedTargetingAge?.length) {
    return []
  }

  const sortedAges =
    selectedTargetingAge?.sort(
      (a, b) => Number(a.slice(0, 2)) - Number(b.slice(0, 2)),
    ) || []

  return sortedAges.reduce((acc, cV) => {
    const prev = Number(acc.at(-1)?.slice(-2))
    const next = cV.includes('+')
      ? Number(cV.slice(-3, -1))
      : Number(cV.slice(0, 2))

    const isAdjacent = next - prev === 1

    if (isAdjacent) {
      const result = `${acc.at(-1).slice(0, 2)}-${
        cV.includes('+') ? cV.slice(-3) : cV.slice(-2)
      }`

      return [...acc.filter(item => !item.includes(prev)), result]
    }

    return [...acc, cV]
  }, [])
}

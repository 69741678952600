import { createAction } from '@reduxjs/toolkit'
import { RootState } from '../../rootState'

export const setIsFormActive = createAction<boolean>('setIsFormActive')

export const setIsFormActiveReducer = (
  state: RootState['app'],
  action: ReturnType<typeof setIsFormActive>,
): void => {
  state.isFormActive = action.payload
}

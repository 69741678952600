import { createAction } from '@reduxjs/toolkit'
import { RootState } from '../../rootState'

export const setNeedReload = createAction<boolean>('setNeedReload')

export const setNeedReloadReducer = (
  state: RootState['app'],
  action: ReturnType<typeof setNeedReload>,
): void => {
  state.needReload = action.payload
}

import { RouteComponentProps } from '@reach/router'
import { PayloadActionCreator, createAction } from '@reduxjs/toolkit'
import { put, takeEvery } from 'redux-saga/effects'
import { UserDataResponse } from '@ads/front-core/interfaces'
import { setAdmin } from '@ads/front-core/store/reducers/admin/setAdminReducer'
import { setMaintenance } from '@ads/front-core/store/reducers/app/setMaintenanceReducer'
import { setUser } from '@ads/front-core/store/reducers/user/setUserReducer'
import { setUserStatus } from '@ads/front-core/store/reducers/user/setUserStatusReducer'
import { setUserLoaded } from '@ads/front-core/store/reducers/user/setUserLoadedReducer'
import getEnv from '@ads/front-core/utils/getEnv'
import { fetchUserData } from './fetchUserDataSaga'

interface AuthorizationProps
  extends Required<Pick<RouteComponentProps, 'navigate' | 'location'>> {
  dryLogin?: boolean
}

type AuthorizationSaga = ReturnType<
  PayloadActionCreator<AuthorizationProps, string>
>

export function* sagaAuthorization(): Generator {
  yield takeEvery(authorization.type, authorizationSaga)
}

export const authorization = createAction<AuthorizationProps>(
  'saga/authorization',
)

export function* authorizationSaga({
  payload: { dryLogin, location, navigate },
}: AuthorizationSaga): Generator {
  const res = yield fetch(`${getEnv('REACT_APP_SSO', '/sso')}/userData`)
  const response = res as Response

  if (response.status === 200) {
    const jsonResponse = yield response.json()
    const json = jsonResponse as UserDataResponse

    if (
      json.maintenance?.status === 'DISABLE_PANEL' ||
      json.maintenance?.status === 'DISABLE_ALL'
    ) {
      yield put(setMaintenance({ maintenance: json.maintenance }))
      yield put(setUserLoaded(true))
      return
    }

    const userStatus =
      json.user.status === 'READY' && json.user.agreements?.length === 0
        ? 'NOT_AGREEMENTS'
        : json.user.status || 'NOT_VERIFIED'

    yield put(setUser(json.user))
    yield put(setUserStatus(userStatus))
    yield put(setAdmin(json.admin))

    if (json.admin?.role) {
      yield put(fetchUserData({ navigate }))
      yield put(setUserLoaded(true))
      return
    }

    if (
      ['UNREGISTERED', 'NOT_VERIFIED', 'NOT_AGREEMENTS'].includes(userStatus)
    ) {
      yield put(setUserLoaded(true))
      return
    }

    yield put(fetchUserData({ navigate }))
    yield put(setUserLoaded(true))
    return
  }

  if (!dryLogin) {
    window.location.assign(
      `${getEnv('REACT_APP_SSO', '/sso')}/auth?backURL=${encodeURIComponent(
        window.location.href,
      )}`,
    )
  }

  yield put(setUserLoaded(true))
}

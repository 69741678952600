import { createAction } from '@reduxjs/toolkit'
import { AppState } from '../../appState'

export const setWizardMode = createAction<AppState['wizard']['mode']>(
  'wizard/setWizardMode',
)

export const setWizardModeReducer = (
  state: AppState['wizard'],
  action: ReturnType<typeof setWizardMode>,
): void => {
  state.mode = action.payload
  state.category = undefined
  state.group = undefined
  state.productType = undefined
}

import { createAction } from '@reduxjs/toolkit'
import { AppState } from '../../appState'

export const setWizardProductType = createAction<
  AppState['wizard']['productType']
>('wizard/setWizardProductType')

export const setWizardProductTypeReducer = (
  state: AppState['wizard'],
  action: ReturnType<typeof setWizardProductType>,
): void => {
  state.productType = action.payload
}

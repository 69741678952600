// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ArgumentTypes<F> = (F extends (...args: infer A) => any
  ? A
  : never)[0]

export * from './duration'
export * from './admin'
export * from './api'
export * from './campaign'
export * from './dictionary'
export * from './feed'
export * from './form'
export * from './sidebar'
export * from './shop'
export * from './routes'
export * from './metrics'
export * from './config'
export * from './upload'
export * from './serve'
export * from './currency'
export * from './audience'
export * from './stepper'

export enum MetricEnum { // TODO to remove
  CR = '1',
  INCOME = '2',
  CLICKS = '3',
  TRANSACTIONS = '4',
  AOV = '5',
  COST = '6',
  ROAS = '7',
  COS = '8',
  VIMP = '9',
  VIEWS = '10',
  CTR = '11',
  V_CTR = '12',
  VIEWABILITY_RATE = '13',
  eCPC = '14',
  DELIVERY = '22',
  OPEN = '23',
  O_CTR = '24',
  OPENRATE = '25',
  SENT = '26',
  ERROR = '27',
}

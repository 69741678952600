import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetConfigResponse } from '@ads/front-core/interfaces'
import { getReleaseVersion } from '@ads/front-core/store/selectors/getReleaseVersion'
import { getIsFormActive } from '@ads/front-core/store/selectors/getIsFormActive'
import { setNeedReload } from '@ads/front-core/store/reducers/app/setNeedReloadReducer'
import { setReleaseVersion } from '@ads/front-core/store/reducers/app/setReleaseVersionReducer'

const CHECK_INTERVAL = 60 * 1000 // in ms, one minute

export const useRelease = () => {
  const dispatch = useDispatch()
  const releaseVersion = useSelector(getReleaseVersion)
  const isFormActive = useSelector(getIsFormActive)

  const currentReleaseRef = useRef<GetConfigResponse['release']>()
  const isFormActiveRef = useRef<boolean>()
  const isWindowVisible = useRef<boolean>(true)

  const fetchRelease = async () => {
    try {
      const res = await fetch('/config')
      const response = res as Response
      if (response?.status === 200) {
        const jsonResponse = (await response?.json()) as GetConfigResponse
        return jsonResponse?.release
      }
    } catch (e) {
      console.error(e)
    }
    return null
  }

  useEffect(() => {
    currentReleaseRef.current = releaseVersion
  }, [releaseVersion])

  useEffect(() => {
    isFormActiveRef.current = isFormActive
  }, [isFormActive])

  useEffect(() => {
    let timerHandler = null

    const checkRelease = async ({
      allowWindowReload,
    }: {
      allowWindowReload: boolean
    }) => {
      const release = await fetchRelease()
      if (
        release?.frontend &&
        release?.backend &&
        currentReleaseRef.current?.frontend &&
        currentReleaseRef.current?.backend &&
        (release.frontend !== currentReleaseRef.current?.frontend ||
          release.backend !== currentReleaseRef.current?.backend)
      ) {
        if (allowWindowReload && !isFormActiveRef.current) {
          window.location.reload()
        } else {
          dispatch(setReleaseVersion(release))
          dispatch(setNeedReload(true))
        }
        return
      }

      timerHandler = setTimeout(() => {
        if (isWindowVisible.current) {
          checkRelease({ allowWindowReload: false })
        }
      }, CHECK_INTERVAL)
    }

    const handleVisibilityChange = () => {
      isWindowVisible.current = !document.hidden
      if (isWindowVisible.current) {
        checkRelease({ allowWindowReload: true })
      } else if (timerHandler) {
        clearTimeout(timerHandler)
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    handleVisibilityChange()

    return () => {
      if (timerHandler) {
        clearTimeout(timerHandler)
      }
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])
}

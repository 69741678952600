export const getCaptchaToken = async (): Promise<string> => {
  if (typeof window?.turnstile?.reset !== 'function') {
    return null
  }
  window.turnstile.reset()
  return new Promise(resolve => {
    const checkGetResponse = () => {
      setTimeout(() => {
        const res = window.turnstile.getResponse()
        if (res) {
          resolve(res)
        } else {
          checkGetResponse()
        }
      }, 200)
    }
    checkGetResponse()
  })
}

import { createAction } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'
import { AppState } from '../../appState'

export const setFormStats = createAction<AppState['form']['stats']>(
  'form/setFormStats',
)

export const setFormStatsReducer = (
  state: AppState['form'],
  action: ReturnType<typeof setFormStats>,
): void => {
  state.stats = cloneDeep(action.payload)
}

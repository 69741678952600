import { createReducer } from '@reduxjs/toolkit'
import { preloadedState } from '../../appState'
import { setWizardMode, setWizardModeReducer } from './setWizardMode'
import { setWizardGroup, setWizardGroupReducer } from './setWizardGroup'
import {
  setWizardCategory,
  setWizardCategoryReducer,
} from './setWizardCategory'
import {
  setWizardProductType,
  setWizardProductTypeReducer,
} from './setWizardProductType'

const wizardReducers = createReducer(preloadedState.wizard, builder => {
  builder.addCase(setWizardMode.type, setWizardModeReducer)
  builder.addCase(setWizardGroup.type, setWizardGroupReducer)
  builder.addCase(setWizardCategory.type, setWizardCategoryReducer)
  builder.addCase(setWizardProductType.type, setWizardProductTypeReducer)
})

export default wizardReducers

import { createAction } from '@reduxjs/toolkit'
import { AppState } from '../../appState'

export const setWizardGroup = createAction<AppState['wizard']['group']>(
  'wizard/setWizardGroup',
)

export const setWizardGroupReducer = (
  state: AppState['wizard'],
  action: ReturnType<typeof setWizardGroup>,
): void => {
  state.group = action.payload
  state.category = undefined
  state.productType = undefined
}

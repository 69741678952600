/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Error } from '../models/Error'
import type { RequestAdminAcceptRegistration } from '../models/RequestAdminAcceptRegistration'
import type { RequestRegisterClient } from '../models/RequestRegisterClient'
import type { ResponseAcceptRegistration } from '../models/ResponseAcceptRegistration'
import type { ResponseCompanyData } from '../models/ResponseCompanyData'
import type { ResponseIsOperatorRegistered } from '../models/ResponseIsOperatorRegistered'
import type { ResponsePublicGetRegistration } from '../models/ResponsePublicGetRegistration'
import type { ResponseRegisterClient } from '../models/ResponseRegisterClient'
import type { ResponseRegistration } from '../models/ResponseRegistration'
import type { ResponseRegistrations } from '../models/ResponseRegistrations'
import type { ResponseViesVatNumberValidation } from '../models/ResponseViesVatNumberValidation'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class RegistrationService {
  /**
   * rejestruje potencjalnego klienta
   * @returns Error unexpected error
   * @returns ResponseRegisterClient dane zapisane
   * @throws ApiError
   */
  public static registerClient({
    captchaToken,
    requestBody,
  }: {
    /**
     * token do validacji captchy
     */
    captchaToken: string
    /**
     * Dane podane w formularzu
     */
    requestBody: RequestRegisterClient
  }): CancelablePromise<Error | ResponseRegisterClient> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/register',
      query: {
        captchaToken: captchaToken,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * rejestruje niezalogowanego potencjalnego klienta
   * @returns Error unexpected error
   * @returns ResponseRegisterClient dane zapisane
   * @throws ApiError
   */
  public static registerUnauthenticatedClient({
    captchaToken,
    requestBody,
  }: {
    /**
     * token do validacji captchy
     */
    captchaToken: string
    /**
     * Dane podane w formularzu
     */
    requestBody: RequestRegisterClient
  }): CancelablePromise<Error | ResponseRegisterClient> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/register-unauthenticated',
      query: {
        captchaToken: captchaToken,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Zwraca informacje o zarejstrowanym(bez logowania) użytkowniku
   *
   * @returns ResponsePublicGetRegistration dane zarejestrowanego klienta
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getRegistrationByHash({
    hash,
  }: {
    /**
     * hash uzyskany podczas rejestracji
     */
    hash: string
  }): CancelablePromise<ResponsePublicGetRegistration | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/registration-unauthenticated/{hash}',
      path: {
        hash: hash,
      },
    })
  }

  /**
   * sprawdza, czy operator jest już zarejestrowany
   * @returns ResponseIsOperatorRegistered czy rekord istnieje w tabeli registration
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static isOperatorRegistered(): CancelablePromise<
    ResponseIsOperatorRegistered | Error
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/is-registered',
    })
  }

  /**
   * zwraca dane teleadresowe firmy(z HBP lub jeżeli nie ma to z ogólnodostępnych baz danych o firmach - bir)
   * @returns ResponseCompanyData dane teleadresowe firmy
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getCompanyData({
    nip,
    captchaToken,
  }: {
    /**
     * NIP firmy
     */
    nip: string
    /**
     * token do validacji captchy
     */
    captchaToken: string
  }): CancelablePromise<ResponseCompanyData | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/company-data/{nip}',
      path: {
        nip: nip,
      },
      query: {
        captchaToken: captchaToken,
      },
    })
  }

  /**
   * sprawdza podany identyfikator vat w ogólnodostępnym serwisie VIES
   * @returns ResponseViesVatNumberValidation informacja czy został poprawnie zwalidowany w VIES
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static viesVatNumberValidation({
    euVatNumber,
    captchaToken,
  }: {
    /**
     * identyfikator Vat z prefiksem kraju
     */
    euVatNumber: string
    /**
     * token do validacji captchy
     */
    captchaToken: string
  }): CancelablePromise<ResponseViesVatNumberValidation | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/vies/check-vat-number',
      query: {
        euVatNumber: euVatNumber,
        captchaToken: captchaToken,
      },
    })
  }

  /**
   * Zwraca listę zarejestrowanych klientów
   *
   * @returns ResponseRegistrations podstawowe dane zarejestrowanych klientów
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getAdminRegistrations({
    page,
    limit,
  }: {
    /**
     * numer strony do pobrania
     */
    page: number
    /**
     * liczba elementów do pobrania
     */
    limit: number
  }): CancelablePromise<ResponseRegistrations | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/admin/registration',
      query: {
        page: page,
        limit: limit,
      },
    })
  }

  /**
   * Zwraca infrmacje o zarejstrowanym użytkowniku
   *
   * @returns ResponseRegistration dane zarejestrowago klienta
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getAdminRegistration({
    registrationId,
  }: {
    /**
     * identyfikator rejestracji z tabeli registration
     */
    registrationId: number
  }): CancelablePromise<ResponseRegistration | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/admin/registration/{registrationId}',
      path: {
        registrationId: registrationId,
      },
    })
  }

  /**
   * usuwa zasób o podanym w parametrze id
   *
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static discardAdminRegistration({
    registrationId,
  }: {
    /**
     * identyfikator rejestracji z tabeli registration
     */
    registrationId: number
  }): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/admin/registration/{registrationId}',
      path: {
        registrationId: registrationId,
      },
    })
  }

  /**
   * Tworzy nowego klienta (akceptacja rejestracji)
   * @returns Error unexpected error
   * @returns ResponseAcceptRegistration dane zapisane
   * @throws ApiError
   */
  public static acceptRegistration({
    requestBody,
  }: {
    /**
     * Dane klienta do utworzenia
     */
    requestBody: RequestAdminAcceptRegistration
  }): CancelablePromise<Error | ResponseAcceptRegistration> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/admin/accept-registration',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Kasuje sklep (a raczej ustawia jego status na removed)
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static deleteShopLogo({
    shopId,
  }: {
    shopId: number
  }): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/shop/logo/{shopId}',
      path: {
        shopId: shopId,
      },
    })
  }
}

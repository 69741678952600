import { createAction } from '@reduxjs/toolkit'
import { RootState } from '../../rootState'

export const setUserLoaded = createAction<boolean>('user/setUserLoaded')

export const setUserLoadedReducer = (
  state: RootState['user'],
  action: ReturnType<typeof setUserLoaded>,
): void => {
  state.isLoaded = action.payload
}

import { addMinutes, format, parseISO, subMinutes } from 'date-fns'
import { enGB, pl } from 'date-fns/locale'
import { currentLanguage } from '@ads/front-core/locales'
import {
  getBrowserTimezoneName,
  getDiffOffsetBrowserTimezone,
} from '@ads/front-core/utils'

const locale = currentLanguage === 'en' ? enGB : pl

export const parseDate = (val?: string): Date | null => {
  if (!val) {
    return null
  }
  const parsedDate = Date.parse(val)
  if (isNaN(parsedDate)) {
    return null
  }
  return new Date(parsedDate)
}

export const locateDateToDate = (val: string): Date | undefined => {
  const match = val
    .match(/^(\d{1,2})\.(\d{1,2})\.(\d{4})$/)
    ?.map(m => Number(m))
  if (match) {
    const newDate = new Date(
      `${match[3]}-${match[2] > 9 ? match[2] : `0${match[2]}`}-${
        match[1] > 9 ? match[1] : `0${match[1]}`
      }`,
    )
    return !isNaN(newDate.getTime()) ? newDate : undefined
  }
  const date = Date.parse(val)
  return !isNaN(date) ? new Date(date) : undefined
}

export const locateDateTimeToDate = (val: string): Date | undefined => {
  const match = val
    .match(/^(\d{1,2})\.(\d{1,2})\.(\d{4}) (\d{1,2}):(\d{1,2})/)
    ?.map(m => Number(m))
  if (match) {
    const newDate = new Date(
      `${match[3]}-${match[2] > 9 ? match[2] : `0${match[2]}`}-${
        match[1] > 9 ? match[1] : `0${match[1]}`
      }T${match[4] > 9 ? match[4] : `0${match[4]}`}:${
        match[5] > 9 ? match[5] : `0${match[5]}`
      }:00`,
    )
    return !isNaN(newDate.getTime()) ? newDate : undefined
  }
  const date = Date.parse(val)
  return !isNaN(date) ? new Date(date) : undefined
}

export const dateToLocateDateTime = (val?: Date | string): string | null => {
  const newDate = val instanceof Date ? val : parseDate(val)
  if (!newDate) {
    return null
  }

  const day = newDate.getDate()
  const month = newDate.getMonth() + 1
  const hours = newDate.getHours()
  const minutes = newDate.getMinutes()
  return `${String(day).padStart(2, '0')}.${String(month).padStart(
    2,
    '0',
  )}.${newDate.getFullYear()} ${hours}:${String(minutes).padStart(2, '0')}`
}

export const dateToLocateDate = (val?: Date | string): string | null => {
  const newDate = val instanceof Date ? val : parseDate(val)
  if (!newDate) {
    return null
  }

  const day = newDate.getDate()
  const month = newDate.getMonth() + 1

  return `${day > 9 ? day : `0${day}`}.${
    month > 9 ? month : `0${month}`
  }.${newDate.getFullYear()}`
}

export const dateToISODate = (val?: Date | string): string | null => {
  const newDate = val instanceof Date ? val : parseDate(val)
  if (!newDate) {
    return null
  }

  const month = newDate.getMonth() + 1
  const day = newDate.getDate()
  return `${newDate.getFullYear()}-${month > 9 ? month : `0${month}`}-${
    day > 9 ? day : `0${day}`
  }`
}

export const dateToISODateTime = (val?: Date | string): string | null => {
  const newDate = val instanceof Date ? val : parseDate(val)
  if (!newDate) {
    return null
  }

  const hours = newDate.getHours()
  const minutes = newDate.getMinutes()
  const seconds = newDate.getSeconds()
  return `${dateToISODate(newDate)} ${hours > 9 ? hours : `0${hours}`}:${
    minutes > 9 ? minutes : `0${minutes}`
  }:${seconds > 9 ? seconds : `0${seconds}`}`
}

export const isDateFromInfinity = (val?: string): boolean => {
  if (!val) {
    return false
  }
  try {
    const date = new Date(val)
    return date.getFullYear() > new Date().getFullYear() + 20
  } catch (e) {
    console.error('isDateFromInfinity', e)
    return false
  }
}

export const dateForRequest = (
  val?: Date | string,
  timezone?: string,
): string | undefined => {
  if (!val) {
    return undefined
  }
  const dateIsoString = val instanceof Date ? val.toISOString() : val
  return appendTimezoneToDate(dateIsoString, timezone)
}

export const dateFromRequest = (val?: string, timezone?: string): string => {
  if (!val) {
    return null
  }

  if (timezone && timezone !== getBrowserTimezoneName()) {
    const diffOffset = getDiffOffsetBrowserTimezone(timezone)
    const date = parseDate(val)
    const fixedDate = subMinutes(date, diffOffset)
    return fixedDate.toISOString()
  }

  const date = parseDate(val)
  return date.toISOString()
}

const appendTimezoneToDate = (val: string, timezone?: string): string => {
  if (timezone && timezone !== getBrowserTimezoneName()) {
    const diffOffset = getDiffOffsetBrowserTimezone(timezone)
    const date = parseDate(val)
    const fixedDate = addMinutes(date, diffOffset)
    return fixedDate.toISOString().replace(/:\d+.\d+Z/, ':00.000Z')
  }
  return val.replace(/:\d+.\d+Z/, ':00.000Z')
}

export const parseISODate = (val?: string): Date | undefined => {
  if (!val) {
    return undefined
  }
  try {
    const date = parseISO(val)
    return !isNaN(date.getTime()) ? date : undefined
  } catch (e) {
    console.warn(`Cannot parse date: ${val}`, e)
  }
}

export const getLocateDate = (val: string | number | Date): string =>
  format(new Date(val), 'd MMMM yyyy', { locale })

export const getLocateDateTime = (val: string | number | Date): string =>
  val ? format(new Date(val), 'dd MMMM yyyy, H:mm', { locale }) : ''

export const getDateTime = (val: string | number | Date): string =>
  val ? format(new Date(val), 'dd.MM.yyyy, H:mm', { locale }) : ''

export const getISODateTime = (val: string | number | Date): string =>
  val ? format(new Date(val), 'yyyy-MM-dd HH:mm:ss', { locale }) : ''

export const getDate = (val: string | number | Date): string =>
  val ? format(new Date(val), 'd.MM.yyyy', { locale }) : ''
